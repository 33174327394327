// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  shiftTypesData: [],
  employeesData: [],
  ganttChartData: [],
  algorithmStatus: "NOT_CREATED",
  algorithmErrors: "",
  ganttGroupData: [],
  ganttItemData: [],
  localLoader: 0,
  dataImportErrorListData: [],
  weekDetailsData: [],
  timeAndDemandData: [],
  barChartData: [],
  onGoingWeekDetailsData: [],
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    ShiftTypesData(state, action) {
      state.shiftTypesData = action.payload;
    },

    EmployeesData(state, action) {
      state.employeesData = action.payload;
    },

    GanttChartData(state, action) {
      state.ganttChartData = action.payload;
    },

    AlgorithmStatus(state, action) {
      state.algorithmStatus = action.payload;
    },

    AlgorithmErrors(state, action) {
      state.algorithmErrors = action.payload;
    },

    GanttGroupData(state, action) {
      state.ganttGroupData = action.payload;
    },

    GanttItemData(state, action) {
      state.ganttItemData = action.payload;
    },

    LocalLoader(state, action) {
      state.localLoader = action.payload;
    },

    DataImportErrorListData(state, action) {
      state.dataImportErrorListData = action.payload;
    },

    WeekDetailsData(state, action) {
      state.weekDetailsData = action.payload;
    },

    TimeAndDemandData(state, action) {
      state.timeAndDemandData = action.payload;
    },

    BarChartData(state, action) {
      state.barChartData = action.payload;
    },

    OnGoingWeekDetailsData(state, action) {
      state.onGoingWeekDetailsData = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  ShiftTypesData,
  EmployeesData,
  GanttChartData,
  AlgorithmStatus,
  AlgorithmErrors,
  GanttGroupData,
  GanttItemData,
  LocalLoader,
  DataImportErrorListData,
  WeekDetailsData,
  TimeAndDemandData,
  BarChartData,
  OnGoingWeekDetailsData,
} = menu.actions;
