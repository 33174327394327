import { Routes, Route } from "react-router-dom";
import Loadable from "../Components/Loadable";
import { lazy } from "react";

// =====================================|| Main Routing ||===================================== //

const Authentication = Loadable(
  lazy(() => import("../Pages/Authentication/Authentication")),
);

const Dashboard = Loadable(lazy(() => import("../Pages/Dashboard/Dashboard")));

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Authentication />} />

      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
};

export default MainRoutes;
